import http from "./httpService.js";
import { apiUrl } from "./settings.js";

const tokenKey = "token";

export const register = (user) => {
  return http.post(apiUrl + "users/", user);
};

export const login = async (loginDetails) => {
  const response = await http.post(apiUrl + "login/", loginDetails);
  localStorage.setItem(tokenKey, response.data.token);
  localStorage.setItem("user", JSON.stringify(response.data));
  return response;
};
