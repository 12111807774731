import { useState, useEffect } from "react";
import { getUtaInt } from "../services/mainService";
import { useNavigate } from "react-router-dom";

const NavBar = () => {
  let navigate = useNavigate();

  const [utaint, setUtaint] = useState([]);

  useEffect(() => {
    getUtaInt(setUtaint);
  }, []);

  const onLogout = () => {
    localStorage.clear();
    navigate(0);
  };

  /*   const navToLogin = () => {
    navigate("/member");
  }; */

  const UtaList = utaint.map((uTa) => (
    <li className="" key={uTa.id}>
      <a href={"/" + uTa.int_ta}>{uTa.int_ta}</a>
      {/*       <button onClick={() => onEditProduct(nugget)}>e</button>
      <button onClick={() => onDeleteProduct(nugget)}>del</button> */}
    </li>
  ));

  return (
    <div>
      <ul>
        <li>
          <a clasname="active" href="/">
            Home
          </a>
        </li>
        {UtaList}
        {/*         <li>
          <a href="#contact">Contact</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li> */}
        {/*         {user ? (
          <button onClick={onLogout}>logout</button>
        ) : (
          <button onClick={navToLogin}>login</button>
        )} */}
        <button onClick={onLogout}>logout</button>
      </ul>
    </div>
  );
};

export default NavBar;
