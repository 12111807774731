import { useState, useEffect } from "react";
import { getThemen, deleteThema } from "../services/mainService";
import { useNavigate } from "react-router-dom";
import CreateThemaModal from "./createThemaModal";

const Themen = () => {
  let navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [themen, setThemen] = useState([]);

  const navToDetails = (selectedId) => {
    navigate("/themaDetails/" + selectedId + "/");
  };

  const deleteThemaClicked = (themaId) => {
    deleteThema(themaId, setThemen);
  };

  const ThemenList = themen.map((thema, key) => (
    <div key={key}>
      <div className="themadelbtn" onClick={() => navToDetails(thema.id)}>
        {thema.thema_item} {/* {thema.content} */}
      </div>
      <button onClick={() => deleteThemaClicked(thema.id)}>del</button>
    </div>
  ));

  useEffect(() => {
    getThemen(setThemen);
  }, []);

  return (
    <>
      <div>
        <h2>Themen</h2>
        <button onClick={() => setIsOpen(true)}>Thema erstellen</button>
      </div>
      {ThemenList}
      {isOpen && (
        <CreateThemaModal
          setIsOpen={setIsOpen}
          setThemen={setThemen}
        ></CreateThemaModal>
      )}
    </>
  );
};

export default Themen;
