import axios from "axios";
if (localStorage.getItem("token") !== null) {
  updateToken();
}

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError) {
    // logger.log(error);
    alert("An unexpected error occurrred.");
  }

  return Promise.reject(error);
});

function updateToken() {
  let token = null;
  if (localStorage.getItem("token") !== null) {
    token = "Token " + localStorage.getItem("token");
  }
  if (token !== null) {
    axios.defaults.headers.common["Authorization"] = token;
  }
}

function setJwt(jwt) {
  axios.defaults.headers.common["x-auth-token"] = jwt;
}

const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt,
  updateToken,
};

export default http;
