import { useState } from "react";
import { login } from "../services/userService.js";
import FormInputs from "../common/FormInputs.jsx";

const Login = ({ setUser, ...props }) => {
  const [loginUser, setLoginUser] = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState(null);
  const [showLogin, setShowLogin] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login(loginUser);
      setUser(response.data);
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        setErrors(ex.response.data);
      }
    }
  };

  return showLogin ? (
    <div className="register">
      <h1>Login</h1>
      <form onSubmit={handleSubmit}>
        <FormInputs input={loginUser} setInput={setLoginUser} errors={errors} />
        <input type="submit" value="submit" />
      </form>
      <button onClick={() => setShowLogin(false)}>Back</button>
    </div>
  ) : (
    <>
      <div className="impressum__content">
        <div>Oliver Henß, M.Sc. </div>
        <p>Steuerberater </p>
        <div>Hattersheimer Straße 19</div>
        <div>069-770 374 14</div>
        <div>0179-45 20 248</div>
        <div>oliver@stb-it.de</div>
      </div>
      <button onClick={() => setShowLogin(true)}>Login</button>
    </>
  );
};

export default Login;
