/* import { useState, useEffect } from "react";
import Login from "./login.jsx"; */

const Home = () => {
  /*   const [user, setUser] = useState("");
  const RegisterLogin = ({ setUser, ...props }) => {
    return (
      <>

        <Login setUser={setUser} />
      </>
    );
  }; */
  /*   useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, []); */
  return (
    /*     <div>
      {user ? <>Hello {user.username}</> : <RegisterLogin setUser={setUser} />}
    </div> */
    <div>
      <h1>homepage</h1>
    </div>
  );
};

export default Home;
