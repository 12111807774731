import { useEffect } from "react";

const TemplInputs = ({
  inputs,
  setInputs,

  ...props
}) => {
  useEffect(() => {}, []);

  /*   const [inputs, setInputs] = useState({
    mt: "",
    kommentar: "",
  }); */

  const handleChange = (event, input) => {
    let items = [...inputs];
    let in_index = inputs.indexOf(input);
    let item = { ...inputs[in_index] };
    item.value = event.target.value;
    items[in_index] = item;
    if (input.name === "einzelpreis") {
      inputs[6].value = item.value * inputs[4].value;
    }
    setInputs(items);
  };

  const onButtonClicked = (input, option) => {
    let items = [...inputs];
    let in_index = inputs.indexOf(input);
    let item = { ...inputs[in_index] };
    item.value = option.description;
    items[in_index] = item;
    setInputs(items);
  };

  return (
    <>
      {inputs.map((input) =>
        input.name !== "id" && input.name !== "source" ? (
          <div key={input.id}>
            <div className="input__label">
              <label htmlFor={input.description}>{input.name}</label>
            </div>
            <div>
              {input.htmlType === "select" ? (
                <>
                  {input.options.map((option, key) => (
                    <button
                      className={
                        input.value === option.description
                          ? "input__btn btnselected"
                          : "input__btn"
                      }
                      key={key}
                      value={option.description}
                      onClick={() => onButtonClicked(input, option)}
                    >
                      {option.description}
                    </button>
                  ))}
                </>
              ) : (
                <input
                  className="appoint__input"
                  required
                  type={input.type}
                  value={input.value || ""}
                  onChange={(e) => handleChange(e, input)}
                />
              )}
            </div>
          </div>
        ) : null
      )}
    </>
  );
};

export default TemplInputs;
