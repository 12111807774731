import { useState, useEffect } from "react";
import Login from "./login.jsx";

const UserHome = () => {
  const [user, setUser] = useState("");
  const RegisterLogin = ({ setUser, ...props }) => {
    return (
      <>
        {/* <Register setUser={setUser} /> */}
        <Login setUser={setUser} />
      </>
    );
  };
  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("user")));
  }, []);
  return (
    <div>
      {user ? <>Hello {user.username}</> : <RegisterLogin setUser={setUser} />}
    </div>
    /*     <div>
      <h1>homepage</h1>
    </div> */
  );
};

export default UserHome;
