import http from "./httpService.js";
import { asysUrl } from "./settings.js";

//const tokenKey = "token";

export const getUtaInt = async (setUtaInt) => {
  http.updateToken();
  const response = await http.get(asysUrl + "/utaint/");
  let utaint = await response.data;
  setUtaInt([...utaint]);
};

export const getThemen = async (setThemen) => {
  const response = await http.get(asysUrl + "/thema/");
  let data = await response.data;
  setThemen([...data]);
};

export const createThema = async (newthemendata, setThemen, setIsOpen) => {
  const response = await http.post(asysUrl + "/thema/", newthemendata);
  let data = await response.data;
  setThemen([...data]);
  setIsOpen(false);
};

export const getThema = async (setThema, themaId, setKommentare) => {
  const response = await http.get(asysUrl + "/themasingle/" + themaId + "/");
  let data = await response.data;
  setThema(data);
  setKommentare([...data.kommentare]);
};

export const createKommentar = async (
  newKommentardata,
  setKommentare,
  themaId
) => {
  const response = await http.post(
    asysUrl + "/themasingle/" + themaId + "/",
    newKommentardata
  );
  let data = await response.data;
  setKommentare([...data]);
};

export const getAgents = async (setAgent) => {
  const response = await http.get(asysUrl + "/agent/");
  let data = await response.data;
  setAgent(data);
};

export const getUserStatus = async (setUserStatus) => {
  const response = await http.get(asysUrl + "/checkuser/");
  let data = await response.data;
  setUserStatus(data);
};

export const createAgent = async (newAgentdata, setAgents) => {
  const response = await http.post(asysUrl + "/agent/", newAgentdata);
  let data = await response.data;
  setAgents([...data]);
};

export const deleteThema = async (themaId, setThemen) => {
  const response = await http.delete(asysUrl + "/themasingle/" + themaId + "/");
  let data = await response.data;
  if (typeof data === "string") {
    alert(data);
  } else {
    setThemen([...data]);
  }
};
