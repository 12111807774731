import { useState, useEffect } from "react";
import TemplInputs from "../common/templInputs";
import { getThema, createKommentar } from "../services/mainService";
import { useParams } from "react-router-dom";

const aInputs = [
  { id: 0, name: "kommentar", htmlType: "input", type: "text", value: "" },
];

const ThemaDetails = () => {
  const { themaId } = useParams();

  const [thema, setThema] = useState({});
  const [kommentare, setKommentare] = useState([]);
  const [inputs, setInputs] = useState(aInputs);

  const KommentarList = kommentare.map((kommentar) => (
    <div className="" key={kommentar.id}>
      {kommentar.kommentar} {/* {thema.content} */}
    </div>
  ));

  const saveKommentar = () => {
    createKommentar(inputs, setKommentare, themaId);
    setInputs(aInputs);
  };

  useEffect(() => {
    getThema(setThema, themaId, setKommentare);
  }, [themaId]);
  return (
    <>
      <div>
        <h2>{thema.thema_item}</h2>
        {thema.content}
      </div>
      <TemplInputs setInputs={setInputs} inputs={inputs}></TemplInputs>
      <button onClick={saveKommentar}>save</button>
      {KommentarList}
    </>
  );
};

export default ThemaDetails;
