import NavBar from "./components/navBar.jsx";
import Home from "./components/home.jsx";
import UserHome from "./components/userHome.jsx";
import Login from "./components/login.jsx";
import Themen from "./components/themen.jsx";
import ThemaDetails from "./components/themaDetails.jsx";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React, { useState } from "react";

import "./App.css";

function App() {
  const [user, setUser] = useState(localStorage.getItem("user"));

  if (!user) {
    return (
      <div className="App">
        <Login setUser={setUser}></Login>
      </div>
      /*       <div className="App">
        <Router>
          <NavBar></NavBar>
          <Login setUser={setUser}></Login>
          <Routes></Routes>
        </Router>
      </div> */
    );
  }

  return (
    <div className="App">
      <Router>
        <NavBar></NavBar>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/member" element={<UserHome />} />
          <Route path="/login" element={<Login />} />
          <Route path="/thema" element={<Themen />} />
          <Route path="/themadetails/:themaId" element={<ThemaDetails />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
