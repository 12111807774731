import { useState, useEffect } from "react";
import TemplInputs from "../common/templInputs";
import {
  createThema,
  getAgents,
  createAgent,
  getUserStatus,
} from "../services/mainService";

const aInputs = [
  { id: 0, name: "content", htmlType: "input", type: "text", value: "" },
  { id: 1, name: "thema_item", htmlType: "input", type: "text", value: "" },
];
const aInputsAgent = [
  { id: 3, name: "description", htmlType: "input", type: "text", value: "" },
];

const CreateThemaModal = ({ setIsOpen, setThemen }) => {
  const [inputs, setInputs] = useState(aInputs);
  const [inputsAgent, setInputsAgent] = useState(aInputsAgent);

  const [agents, setAgents] = useState([]);
  const [agentId, setAgent] = useState("");
  const [agentIdRec, setAgentRec] = useState("");
  const [showCreateAgent, setShowCreateAgent] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    getAgents(setAgents);
    getUserStatus(setIsAdmin);
  }, []);

  const createNewAgent = () => {
    createAgent(inputsAgent, setAgents);
    setInputsAgent(inputsAgent);
    setShowCreateAgent(false);
  };

  const saveThema = () => {
    let sendObj = { inputs: inputs, agentid: agentId, receiver: agentIdRec };
    createThema(sendObj, setThemen, setIsOpen);
    setInputs(aInputs);
  };

  const Agents = agents.map((agent) => (
    <button
      className={agent.id === agentId ? "input__btn btnselected" : "input__btn"}
      key={agent.id}
      onClick={() => setAgent(agent.id)}
    >
      {agent.description}
    </button>
  ));
  const AgentsRec = agents.map((agent) => (
    <button
      className={
        agent.id === agentIdRec ? "input__btn btnselected" : "input__btn"
      }
      key={agent.id}
      onClick={() => setAgentRec(agent.id)}
    >
      {agent.description}
    </button>
  ));

  return (
    <div className="create__thema_modal">
      <div className="create__thema_content">
        <h1>Neues Thema ertellen</h1>
        <div>
          <TemplInputs setInputs={setInputs} inputs={inputs}></TemplInputs>
          {Agents}
          {isAdmin && <div>{AgentsRec}</div>}

          <button onClick={() => setShowCreateAgent(!showCreateAgent)}>
            {!showCreateAgent ? "neu erstellen" : "abbrechen"}
          </button>
          {showCreateAgent ? (
            <>
              <TemplInputs
                setInputs={setInputsAgent}
                inputs={inputsAgent}
              ></TemplInputs>
              <button onClick={createNewAgent}>create</button>
            </>
          ) : null}
        </div>

        <div className="book__appoint__btn_area">
          <button className="btn__kobalt" onClick={() => setIsOpen(false)}>
            abbrechen
          </button>
          <button
            disabled={inputs[0].value === "" || inputs[1].value === ""}
            className="btn__kobalt"
            onClick={() => saveThema()}
          >
            erstellen
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateThemaModal;
